<template>
  <v-card max-width="800px" class="mx-auto">
    <v-card-title>
      Service Ports
      <v-btn
        color="success"
        icon
        rounded
        @click="addPort"
      >
        <v-icon>fal fa-plus</v-icon>
      </v-btn>
      <v-spacer />
      <v-text-field
        v-model="search"
        label="Search"
        dense
        hide-details
        outlined
        style="max-width: 300px"
      />
    </v-card-title>
    <v-card-subtitle>A list of all ports in use by kubernetes services</v-card-subtitle>
    <v-data-table
      :headers="headers"
      :items="port"
      :search="search"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template
        v-slot:item.name="{ item }"
      >
        <div style="cursor: pointer" @click="editPort(item)">
          {{ item.name }}
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogUpsert" max-width="400px">
      <v-card>
        <v-card-title class="pb-7">
          {{ dialogUpsertId ? 'Edit Port' : 'Add Port' }}
          <v-spacer />
          <v-btn
            v-id="dialogUpsertId"
            color="error"
            icon
            :disabled="dialogUpsertSaving"
            :loading="dialogUpsertSaving"
            @click="deletePort"
          >
            <v-icon>fal fa-trash-alt</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="dialogUpsert" lazy-validation>
            <v-text-field
              v-model="dialogUpsertName"
              label="Service Name"
              outlined
              :rules="[v => !!v || 'Please enter a name']"
              validate-on-blur
            />
            <v-text-field
              v-model="dialogUpsertPort"
              label="TCP Port"
              outlined
              :rules="[
                v => !!v || 'Please enter a port',
                v => !isNaN(v) || 'Only numbers allowed in port',
                v => (v > 0 && v <= 65535) || 'Port must be between 1 and 65,535',
              ]"
              validate-on-blur
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn
            color="error"
            text
            :disabled="dialogUpsertSaving"
            :loading="dialogUpsertSaving"
            @click="dialogUpsert = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            text
            :disabled="dialogUpsertSaving"
            :loading="dialogUpsertSaving"
            @click="upsertSave"
          >
            {{ dialogUpsertId ? 'Update Port' : 'Create Port' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { db } from "@/firestore";
export default {
  name: 'ServicePort',
  data() {
    return {
      deleteJob: false,
      deleteJobVisible: false,
      dialogUpsert: false,
      dialogUpsertId: false,
      dialogUpsertName: "",
      dialogUpsertPort: "",
      dialogUpsertSaving: false,
      headers: [
        {
          text: 'Service Name',
          align: 'left',
          value: 'name',
        },
        {
          text: 'TCP Port',
          align: 'right',
          value: 'port',
        }
      ],
      port: [],
      search: '',
      sortBy: 'port',
      sortDesc: false
    };
  },
  methods: {
    addPort() {
      this.dialogUpsertId = false;
      this.dialogUpsertName = "";
      this.dialogUpsertPort = "";
      this.dialogUpsert = true;
    },
    deletePort() {
      this.dialogUpsertSaving = true;
      db.collection('service-port').doc(this.dialogUpsertId).delete().then(() => {
        this.$notify({
          type: 'success',
          title: 'Port deleted'
        });
        this.dialogUpsert = false;
        this.dialogUpsertSaving = false;
      });
    },
    editPort(item) {
      this.dialogUpsertId = item.id;
      this.dialogUpsertName = item.name;
      this.dialogUpsertPort = item.port;
      this.dialogUpsert = true;
    },
    upsertSave() {
      if (this.$refs.dialogUpsert.validate()) {
        const data = {
          name: this.dialogUpsertName,
          port: this.dialogUpsertPort
        };
        this.dialogUpsertSaving = true;
        if (this.dialogUpsertId) {
          db.collection('service-port').doc(this.dialogUpsertId).update(data).then(() => {
            this.$notify({
              type: 'success',
              title: 'Port updated'
            });
            this.dialogUpsert = false;
            this.dialogUpsertSaving = false;
          });
        } else {
          db.collection('service-port').add(data).then((
          ) => {
            this.$notify({
              type: 'success',
              title: 'Port added'
            });
          });
          this.dialogUpsert = false;
          this.dialogUpsertSaving = false;
        }
      }
    }
  },
  firestore: {
    port: db.collection('service-port'),
  },
};
</script>


<style scoped lang="scss">
</style>
